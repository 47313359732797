import { Form, Row, Col, Input, InputNumber, Select } from "antd";
import { HinhThuc } from "../../Variable";

const { TextArea } = Input;

export const SalarySlipForm = () => {
   return (
      <>
         <Row gutter={20}>
            <Col span={12}>
               <Form.Item
                  label="Mã Nhân Viên"
                  name="maNhanVien"
                  rules={[
                     {
                        required: true,
                        message: "Vui lòng nhập mã nhân viên!",
                     },
                  ]}
               >
                  <InputNumber min={0} style={{ width: "100%" }} />
               </Form.Item>
            </Col>
            <Col span={12}>
               <Form.Item
                  label="Họ và tên"
                  name="hoTen"
                  rules={[
                     {
                        required: true,
                        message: "Vui lòng nhập họ và tên!",
                     },
                  ]}
               >
                  <Input />
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={20}>
            <Col span={12}>
               <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                     {
                        required: true,
                        message: "Vui lòng nhập email!",
                     },
                  ]}
               >
                  <Input />
               </Form.Item>
            </Col>
            <Col span={12}>
               <Form.Item
                  label="Bộ phân"
                  name="boPhan"
                  rules={[
                     {
                        required: true,
                        message: "Vui lòng nhập bộ phận làm việc!",
                     },
                  ]}
               >
                  <Input />
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={20}>
            <Col span={12}>
               <Form.Item
                  label="Thời gian làm việc"
                  name="hinhThuc"
                  rules={[
                     {
                        required: true,
                        message: "Vui lòng chọn thời gian làm việc!",
                     },
                  ]}
               >
                  <Select>
                     {Object.values(HinhThuc).map((item, i) => (
                        <Select.Option key={`hinh-thuc-${i}`} value={item}>
                           {item}
                        </Select.Option>
                     ))}
                  </Select>
               </Form.Item>
            </Col>
            <Col span={12}>
               <Form.Item
                  label="Lương cơ bản"
                  name="luongCoBan"
                  rules={[
                     {
                        required: true,
                        message: "Vui lòng nhập lương cơ bản!",
                     },
                  ]}
               >
                  <InputNumber
                     min={0}
                     style={{ width: "100%" }}
                     formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                     }
                     parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
               </Form.Item>

               {/* <Form.Item
                  label="Lương cơ bản TB"
                  name="luongCoBanTB"
                  rules={[
                     {
                        required: true,
                        message: "Vui lòng nhập lương cơ bản TB!",
                     },
                  ]}
               >
                  <InputNumber
                     min={0}
                     style={{ width: "100%" }}
                     formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                     }
                     parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
               </Form.Item> */}
            </Col>
         </Row>
         <Row gutter={20}>
            <Col span={12}>
               <Form.Item
                  label="Phụ cấp gửi xe"
                  name="phuCapGuiXe"
                  rules={[
                     {
                        required: true,
                        message: "Vui lòng nhập phụ cấp gửi xe!",
                     },
                  ]}
               >
                  <InputNumber
                     min={0}
                     style={{ width: "100%" }}
                     formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                     }
                     parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
               </Form.Item>
            </Col>
            <Col span={12}>
               <Form.Item
                  label="Phụ cấp ăn"
                  name="phuCapAn"
                  rules={[
                     {
                        required: true,
                        message: "Vui lòng nhập phụ cấp ăn!",
                     },
                  ]}
               >
                  <InputNumber
                     min={0}
                     style={{ width: "100%" }}
                     formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                     }
                     parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={20}>
            <Col span={12}>
               <Form.Item
                  label="Số ngày làm việc"
                  name="soNgayLam"
                  rules={[
                     {
                        required: true,
                        message: "Vui lòng nhập số ngày làm!",
                     },
                  ]}
               >
                  <InputNumber min={0} style={{ width: "100%" }} />
               </Form.Item>
            </Col>
            {/* <Col span={12}>
               <Form.Item
                  label="Số ngày nghỉ"
                  name="soNgayNghi"
                  rules={[
                     {
                        required: true,
                        message: "Vui lòng nhập số ngày nghỉ!",
                     },
                  ]}
               >
                  <InputNumber min={0} style={{ width: "100%" }} />
               </Form.Item>
            </Col> */}
         </Row>
         <Row gutter={20}>
            <Col span={12}>
               <Form.Item
                  label="Số phép nghỉ"
                  name="soPhepNghi"
                  rules={[
                     {
                        required: true,
                        message: "Vui lòng nhập số phép nghỉ!",
                     },
                  ]}
               >
                  <InputNumber min={0} style={{ width: "100%" }} />
               </Form.Item>
            </Col>
            <Col span={12}>
               <Form.Item
                  label="Tiền tạm ứng"
                  name="tienTamUng"
                  rules={[
                     {
                        required: true,
                        message: "Vui lòng nhập tiền tạm ứng!",
                     },
                  ]}
               >
                  <InputNumber
                     min={0}
                     style={{ width: "100%" }}
                     formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                     }
                     parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={20}>
            <Col span={12}>
               <Form.Item
                  label="Tiền bảo hiểm"
                  name="tienBaoHiem"
                  rules={[
                     {
                        required: true,
                        message: "Vui lòng nhập tiền bảo hiểm!",
                     },
                  ]}
               >
                  <InputNumber
                     min={0}
                     style={{ width: "100%" }}
                     formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                     }
                     parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
               </Form.Item>
            </Col>
            <Col span={12}>
               <Form.Item
                  label="Tiền tăng ca"
                  name="tangCa"
                  rules={[
                     {
                        required: true,
                        message: "Vui lòng nhập tiền tăng ca!",
                     },
                  ]}
               >
                  <InputNumber
                     min={0}
                     style={{ width: "100%" }}
                     formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                     }
                     parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={20}>
            <Col span={12}>
               <Form.Item
                  label="Tiền phạt"
                  name="phat"
                  rules={[
                     {
                        required: true,
                        message: "Vui lòng nhập tiền phạt!",
                     },
                  ]}
               >
                  <InputNumber
                     min={0}
                     style={{ width: "100%" }}
                     formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                     }
                     parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
               </Form.Item>
            </Col>
            <Col span={12}>
               <Form.Item
                  label="Tiền Thưởng"
                  name="thuong"
                  rules={[
                     {
                        required: true,
                        message: "Vui lòng nhập tiền thưởng!",
                     },
                  ]}
               >
                  <InputNumber
                     min={0}
                     style={{ width: "100%" }}
                     formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                     }
                     parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
               </Form.Item>
            </Col>
         </Row>

         <Row gutter={20}>
            <Col span={12}>
               <Form.Item
                  label="Tiền trợ cấp bảo hiểm"
                  name="troCapBaoHiem"
                  rules={[
                     {
                        required: true,
                        message: "Vui lòng nhập tiền trợ cấp bảo hiểm!",
                     },
                  ]}
               >
                  <InputNumber
                     min={0}
                     style={{ width: "100%" }}
                     formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                     }
                     parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
               </Form.Item>
            </Col>
            <Col span={12}>
               <Form.Item label="Ghi chú" name="ghiChu">
                  <TextArea rows={4} />
               </Form.Item>
            </Col>
         </Row>

         {/* <Row gutter={20}>
            <Col span={12}>
               <Form.Item
                  label="Lương Tháng 13"
                  name="luongThang13"
                  // rules={[
                  //    {
                  //       required: true,
                  //       message: "Vui lòng nhập Lương Tháng 13!",
                  //    },
                  // ]}
               >
                  <InputNumber
                     min={0}
                     style={{ width: "100%" }}
                     formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                     }
                     parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
               </Form.Item>
               <Form.Item
                  label="Thưởng Tết Dương Lịch 2025"
                  name="thuongTetDuongLich2025"
                  // rules={[
                  //    {
                  //       required: true,
                  //       message: "Vui lòng nhập Thưởng Tết Dương Lịch 2025!",
                  //    },
                  // ]}
               >
                  <InputNumber
                     min={0}
                     style={{ width: "100%" }}
                     formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                     }
                     parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
               </Form.Item>
               <Form.Item
                  label="Set Quà Tết 2025"
                  name="setQuaTet2025"
                  // rules={[
                  //    {
                  //       required: true,
                  //       message: "Vui lòng nhập Set Quà Tết 2025!",
                  //    },
                  // ]}
               >
                  <InputNumber
                     min={0}
                     style={{ width: "100%" }}
                     formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                     }
                     parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
               </Form.Item>
               <Form.Item
                  label="Tiền Tạm Ứng"
                  name="tienTamUng"
                  // rules={[
                  //    {
                  //       required: true,
                  //       message: "Vui lòng nhập Tiền Tạm Ứng!",
                  //    },
                  // ]}
               >
                  <InputNumber
                     min={0}
                     style={{ width: "100%" }}
                     formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                     }
                     parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
               </Form.Item>
            </Col>
            <Col span={12}>
            <Form.Item
                  label="Thưởng Tết 2025"
                  name="thuongTet"
                  // rules={[
                  //    {
                  //       required: true,
                  //       message: "Vui lòng nhập Thưởng Tết 2025!",
                  //    },
                  // ]}
               >
                  <InputNumber
                     min={0}
                     style={{ width: "100%" }}
                     formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                     }
                     parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
               </Form.Item>
               <Form.Item
                  label="Thưởng Tết Vị Trí"
                  name="thuongViTri"
                  // rules={[
                  //    {
                  //       required: true,
                  //       message: "Vui lòng nhập Thưởng Tết Vị Trí!",
                  //    },
                  // ]}
               >
                  <InputNumber
                     min={0}
                     style={{ width: "100%" }}
                     formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                     }
                     parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
               </Form.Item>
               <Form.Item
                  label="Thưởng YEP"
                  name="thuongYEP"
                  // rules={[
                  //    {
                  //       required: true,
                  //       message: "Vui lòng nhập Thưởng YEP!",
                  //    },
                  // ]}
               >
                  <InputNumber
                     min={0}
                     style={{ width: "100%" }}
                     formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                     }
                     parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
               </Form.Item>
               
               <Form.Item label="Ghi chú" name="ghiChu">
                  <TextArea rows={4} />
               </Form.Item>
            </Col>
         </Row> */}
      </>
   );
};
